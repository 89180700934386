<template>
  <div class="w-full write_box">
    <div class="w-full mb-4 page_box">
      <div class="px-2 pt-2 text-left text-gray-500 path">
        首页-个人中心-保修查询-工单详情-填写物流单号
      </div>
    </div>
    <div class="px-2 pb-4">
      <div class="flex items-center p-3 my-2 rounded" style="background: #FAE7E8;">
        <img src="../../../../assets/images/common/address.png" alt="" class="img_icon" />
        <div class="ml-2">
          <!-- <div class="text-left">
            <span>王嘻嘻</span>
            <span class="ml-2">18855859878</span>
          </div>
          <div>
            广东省深圳市龙岗区华南城1号商业楼
          </div> -->
          <div v-html="orderInfo.platformReceiving"></div>
        </div>
      </div>
      <div class="w-full py-2 md:w-2/5">
        <el-form ref="form" :model="form" label-width="85px">
          <el-form-item label="物流公司：">
            <el-select v-model="form.name" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in list" :key="item.value" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号：">
            <el-input v-model="form.shipmentNumber"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex justify-center mt-2 md:justify-start">
        <div class="w-1/2 py-2 text-white rounded cursor-pointer bg-primary order_btn md:w-1/4" @click="checkRules">
          提交
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        name: "", // 物流单号
        shipmentNumber: "", // 快递单号
      },
      warrantyId: "", // 物流单号
      list: [], // 物流公司数组
      orderInfo: {},
    };
  },
  watch: {},
  created() {
    this.getkuaidiCode();
    let orderInfo = localStorage.getItem("ORDER_INFO");
    if (orderInfo) {
      this.orderInfo = Object.assign({}, JSON.parse(orderInfo));
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    // 获取快递公司
    getkuaidiCode() {
      this.$request({
        url: this.$requestPath.getkuaidiCode,
        method: "GET",
      }).then((res) => {
        console.log("获取的物流公司", res);
        if (res.code == 0) {
          this.list = [];
          this.list.push(...res.data);
        } else {
          this.$EventBus.$emit("toast", { type: "error", message: res.msg });
        }
      });
    },
    // 实名校验
    checkRules() {
      if (this.isNull(this.form.name)) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "物流公司不能为空",
        });
        return;
      }
      if (this.isNull(this.form.shipmentNumber)) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "物流单号不能为空",
        });
        return;
      }

      // console.log(this.form)
      this.warrantyOrder();
    },
    // 提交快递单号
    warrantyOrder() {
      let data = {
        shipmentNumber: this.form.shipmentNumber,
        userId: this.userInfo.userId,
        warrantyId: this.orderInfo.warrantyId,
        company: this.form.name,
      };
      // console.log("data", data)
      // return
      this.$request({
        method: "post",
        url: this.$requestPath.warrantyOrder,
        data,
      })
        .then((res) => {
          console.log("订单提交", res);
          if (res.code == 0) {
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "提交成功",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/index/warranty/qurey",
              });
            }, 1500);
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取用户设备", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table,
td,
th {
  text-align: center;
  border: 1px solid #000;
  border-collapse: collapse;
}

.write_box {
  min-height: var(--min-height);
}

@media screen and (max-width: 640px) {
  .path {
    font-size: 0.875rem;
  }

  .left_td {
    width: 6.25rem;
  }

  .img_icon {
    width: 1.875rem;
  }
}

@media screen and (min-width: 640px) {
  .path {
    font-size: 1rem;
  }

  .left_td {
    width: 15.625rem;
  }

  .img_icon {
    width: 2.5rem;
  }
}

.tabitem-enter-active,
.tabitem-leave-active {
  transition: all 0.6s ease;
}

.tabitem-enter,
.tabitem-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}

.result-enter-active,
.result-leave-active {
  transition: all 0.6s ease;
}

.result-enter,
.result-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}
</style>
